import React from 'react';
import styled from 'styled-components';
import { TwoCol } from '../style/TwoCol';
import { Color } from '../style/Color';
import { dropShadow } from '../style/dropShadow';
import { InternalPage } from '../style/InternalPage';

export type Props = {};

const Holder = styled.div``;

const MapHolder = styled.div`
	background-color: ${Color.BG};
	width: 100%;
	padding: 20px;
	box-sizing: border-box;

	iframe {
		border: 8px solid ${Color.HILIGHT};
		box-sizing: border-box;
		${dropShadow};
		width: 100%;
		height: 500px;
	}
`;

const Component: React.FC<Props> = props => {
	const mapsURL = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d38969.027586783464!2d-74.01156112193036!3d40.750375694356784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259b7c659a557%3A0x11b250525097b57f!2s555+W+25th+St%2C+New+York%2C+NY+10001!5e0!3m2!1sen!2sus!4v1555277328911!5m2!1sen!2sus`;

	return (
		<InternalPage>
			<Holder>
				<section>
					<h1>Careers</h1>
					<p>
						Sloss Eckhouse Dasti Haynes LawCo is committed to being a diverse
						law firm with high-achieving attorneys and staff who have meaningful
						relationships with each other and with our clients. To learn more
						about opportunities to grow your career at the firm, as a lawyer or
						a member of our professional staff, please send a cover letter and
						resume to{' '}
						<a href={`mailto:careers@slosslaw.com`}>careers@slosslaw.com</a>.
					</p>
					<p>
						<strong>Associates</strong>
					</p>
					<p>
						We look to hire new associates who are intellectually curious,
						engaging, entrepreneurial and collaborative, and who desire early
						responsibility and are dedicated to exceptional client service.
						Please note that the firm does not ordinarily hire attorneys
						directly out of law school, and most of our attorneys have prior law
						firm experience. Only candidates who are selected for interviews
						will be contacted. We do not accept unsolicited résumés from search
						firms or employment agencies.
					</p>
					
					<p>
						<strong>Legal Intern Program</strong>
					</p>
					<p>
						We maintain a selective summer internship program. Please submit resume and cover letter by January 31st.  Candidates
						who are selected for interviews will be contacted by March 1st.{' '}
					</p>
					<p>
						<strong>Business Professionals</strong>
					</p>
					<p>
						We offer many opportunities for non-legal staff, including
						paralegals, assistants, and other positions. Only candidates who are
						selected for interviews will be contacted. We do not accept
						unsolicited résumés from search firms or employment agencies.
					</p>
				</section>
			</Holder>
		</InternalPage>
	);
};

export default Component;
